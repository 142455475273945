import { render, staticRenderFns } from "./ServicesFresado.vue?vue&type=template&id=550f6c80&"
import script from "./ServicesFresado.vue?vue&type=script&lang=js&"
export * from "./ServicesFresado.vue?vue&type=script&lang=js&"
import style0 from "./ServicesFresado.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VContainer,VFlex,VImg,VLayout,VResponsive})
