<template>
    <section id="secFresadoService">
        <v-container class="fill-height px-4 py-12">
            <v-responsive
                dark
                class="d-flex align-start text-position"
                height="100%"
                width="100%"
            >
                <v-container>
                    <v-layout wrap>
                        <v-flex
                            d-flex flex-column md-12 xl-6 align-center 
                            class="mb-responsive"
                        >
                            <div class="w--grid--header--service">
                                <v-img
                                    width="70%"
                                    class="mx-auto"
                                    :src= "img"
                                    data-aos="flip-left"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                    data-aos-offset="300"
                                />
                            </div>
                            <div class="w--grid--header--service mt-3">
                                <div>
                                    <h1
                                        class="mb-15 text-h6 text--white text-md-h4 font-weight-black text-center text-uppercase"
                                        data-aos="fade-up"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    >
                                        {{ title }}
                                    </h1>
                                    <p
                                    v-for="(text, i) in  texts"
                                    :key="i"
                                    class="text-center text--white text-lg-h6 text-body-1 font-weight-light"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                    data-aos-offset="300"
                                    >
                                        {{ text }}
                                    </p>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-responsive>
        </v-container>
    </section>
</template>
<script>
export default {
    name: 'SectionServicesFresado',
    data() {
        return {
            img: require('@/assets/img/logo-services-microfresado-transparente.png'),
            title: 'Fresado',
            texts: [
                'Consiste en la demolición de capas existentes de pavimentos asfálticos y/o bases granulares, dejando la superficie a reparar nivelada y uniforme a la profundidad requerida, que puede llegar hasta 25 centímetros por cada pasada o ciclo de recorrido.',
                'El ancho de corte de fresado con nuestros equipos puede ser de 1.0, 1.5 o 2.0 metros, dejando completamente verticales los bordes del corte realizado. Esto representa una gran ventaja al no afectar o lesionar la estructura del pavimento adyacente al sitio que se va a reparar, ni la estructura de la base, en caso que solo sea necesario solamente reconstruir la capa asfáltica.',
            ],
        }
    },
}
</script>

<style lang="scss">
#secFresadoService {
    background-color: #595F63;
    .text-position {
        .title--bann-service {
            font-size: 2rem;
            width: 90%;
            letter-spacing: 1px;
            text-transform: uppercase;
            line-height: 1.1;
            position: relative;
            padding: 0 24vw 0 1vw;
        }
        .cert-txt { font-size: 0.875rem;}
    }
}
.text--white { color: white; }
</style>